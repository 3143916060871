<template>
  <div>
    <section class="invoice-preview-wrapper">
      <b-row v-if="Model" class="invoice-preview">
        <!-- Col: Left (Invoice Container) -->
        <b-col cols="12" xl="9" md="8">
          <b-card no-body class="invoice-preview-card">
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">
              <div
                class="
                  d-flex
                  justify-content-between
                  flex-md-row flex-column
                  invoice-spacing
                  mt-0
                "
              >
                <!-- Header: Left Content -->
                <b-col cols="12" md="6">
                  <div v-if="Model.Patient ">
                    <imageLazy
                      :imageStyle="{
                        'border-radius': '40px',
                        width: '80px',
                        height: '80px',
                        size: '100px',
                      }"
                      :imageClass="'patientImage'"
                      :src="baseURL + Model.Patient.ProfileImagePath"
                      placeHolderType="patient"
                    />
                    <div>
                      <h1
                        v-if="Model.Patient"
                        style="color: black; font-size: 30px"
                      >
                        {{ Model.Patient.Name }}
                      </h1>
                    </div>
                  </div>
                </b-col>
                <b-col cols="12" md="6">
                  <div>
                    <p
                      @click="showPatientHistory(Model.PatientID)"
                      style="text-decoration-line: underline; color: black"
                    >
                      {{ $t("PatientMedicalHistory") }}
                    </p>
                  </div>
                  <div>
                    <p
                      @click="
                        showDownloadReports(Model.PatientID, Model.DoctorID)
                      "
                      style="text-decoration-line: underline; color: black"
                    >
                      {{ $t("PatientMedicalReports") }}
                    </p>
                  </div>
                  <div>
                    <p
                      @click="openPatientsSymptoms(Model.ID)"
                      style="text-decoration-line: underline; color: black"
                    >
                      {{ $t("PatientsSymptomsDescription") }}
                    </p>
                  </div>
                </b-col>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <b-card-body class="invoice-padding pt-0 pb-0">
              <b-row class="invoice-spacing">
                <b-col
                  class="m-1"
                  cols="12"
                  md="3"
                  style="border-right: 1px solid rgba(60, 60, 60, 0.26)"
                >
                  <b-row>
                    <p class="text-primary invoice-logo">
                      {{ $t("DoctorsNotes") }}
                    </p>

                    <b-col>
                      <feather-icon
                        class="cursor-pointer"
                        :style="{ color: editDoctorNote ? '#004477' : 'red' }"
                        icon="EditIcon"
                        @click.stop="editDoctorNote = !editDoctorNote"
                      ></feather-icon>
                      <feather-icon
                        style="color: primary; margin-left: 10px"
                        icon="SaveIcon"
                        :style="{ color: editDoctorNote ? 'red' : '#004477' }"
                        @click.stop="SaveDoctorNotePatientDiagnosisNote()"
                        class="cursor-pointer"
                        v-show="editDoctorNote"
                      >
                      </feather-icon>
                    </b-col>
                  </b-row>
                  <b-form-textarea
                    :disabled="!editDoctorNote"
                    v-model="Model.DoctorComment"
                    height="30%"
                    rows="2"
                    style="
                      border-radius: 16px;
                      opacity: 1;
                      background: #fefefe 0% 0% no-repeat padding-box;
                      width: 80%;
                    "
                  />
                </b-col>

                <b-col
                  class="m-1"
                  cols="12"
                  md="5"
                  style="border-right: 1px solid rgba(60, 60, 60, 0.26)"
                >
                  <b-row>
                    <p class="text-primary invoice-logo">
                      {{ $t("Diagnosis") }}
                    </p>

                    <b-col>
                      <feather-icon
                        :style="{
                          color: editPatientDiagnosis ? '#004477' : 'red',
                        }"
                        icon="EditIcon"
                        @click.stop="
                          editPatientDiagnosis = !editPatientDiagnosis
                        "
                        class="cursor-pointer"
                      ></feather-icon>
                      <feather-icon
                        style="color: primary; margin-left: 10px"
                        :style="{
                          color: editPatientDiagnosis ? 'red' : '#004477',
                        }"
                        icon="SaveIcon"
                        @click.stop="SavePatientDiagnosis()"
                        class="cursor-pointer"
                        v-show="editPatientDiagnosis"
                      >
                      </feather-icon>
                    </b-col>
                  </b-row>
                  <b-card
                    class="mb-3"
                    height="70%"
                    style="
                      border-radius: 16px;
                      opacity: 1;
                      background: #fefefe 0% 0% no-repeat padding-box;
                    "
                  >
                    <b-row class="p-0">
                      <b-col cols="12" md="10">
                        <v-select
                          :dir="$vs.rtl ? 'rtl' : 'ltr'"
                          :disabled="!editPatientDiagnosis"
                          label="Name"
                          v-model="DiagnosModel.Diagnos"
                          :options="Diagnosis"
                          placeholder="Diagnosis"
                          :reduce="(obj) => obj"
                        />
                      </b-col>
                      <b-col cols="12" md="1">
                        <feather-icon
                          :disabled="!editPatientDiagnosis"
                          style="margin-left: 10px; color: black"
                          icon="PlusIcon"
                          @click.stop="AddPatientDiagnosis()"
                          class="cursor-pointer"
                          v-show="editPatientDiagnosis"
                        >
                        </feather-icon>
                      </b-col>
                    </b-row>

                    <div
                      v-if="
                        Model.PatientReservationSessionDiagnosis != null &&
                        Model.PatientReservationSessionDiagnosis != undefined
                      "
                    >
                      <table
                        ref="table"
                        style="background: white"
                        class="text-center w-full"
                      >
                        <tr
                          class=""
                          :data="item"
                          :key="indextr"
                          v-for="(
                            item, indextr
                          ) in Model.PatientReservationSessionDiagnosis"
                        >
                          <td>
                            <p class="mt-1">
                              {{ item.Diagnosis.Name }}
                            </p>
                          </td>
                          <td>
                            <feather-icon
                              :disabled="!editPatientDiagnosis"
                              style="color: black"
                              icon="XIcon"
                              @click.stop="deletePatientDiagnosis(index)"
                              class="m-1"
                            ></feather-icon>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </b-card>
                </b-col>

                <b-col class="m-1" cols="12" md="3">
                  <b-row>
                    <p class="text-primary invoice-logo">
                      {{ $t("DiagnosisNote") }}
                    </p>

                    <b-col>
                      <feather-icon
                        :style="{
                          color: editPatientDiagnosisNote ? '#004477' : 'red',
                        }"
                        icon="EditIcon"
                        @click.stop="
                          editPatientDiagnosisNote = !editPatientDiagnosisNote
                        "
                        class="cursor-pointer"
                      ></feather-icon>
                      <feather-icon
                        style="color: primary; margin-left: 10px"
                        :style="{
                          color: editPatientDiagnosisNote ? 'red' : '#004477',
                        }"
                        icon="SaveIcon"
                        @click.stop="SaveDoctorNotePatientDiagnosisNote()"
                        class="cursor-pointer"
                        v-show="editPatientDiagnosisNote"
                      ></feather-icon>
                    </b-col>
                  </b-row>
                  <b-form-textarea
                    v-model="Model.DiagnosisNote"
                    height="30%"
                    :disabled="!editPatientDiagnosisNote"
                    style="
                      border-radius: 16px;
                      opacity: 1;
                      background: #fefefe 0% 0% no-repeat padding-box;
                      width: 80%;
                    "
                    rows="2"
                  />
                </b-col>
              </b-row>
            </b-card-body>
            <hr class="invoice-spacing mt-0" />
            <b-card-body class="invoice-padding pt-0 pb-0">
              <b-row class="invoice-spacing">
                <b-col cols="12" md="10">
                  <p class="text-primary invoice-logo">
                    {{ $t("Furtherrequestedscansanalysis") }}
                  </p>
                </b-col>
                <b-col cols="12" md="2">
                  <feather-icon
                    :style="{ color: editDoctorRequest ? '#004477' : 'red' }"
                    icon="EditIcon"
                    @click.stop="editDoctorRequest = !editDoctorRequest"
                    class="cursor-pointer"
                  ></feather-icon>
                  <feather-icon
                    :style="{ color: editDoctorRequest ? 'red' : '#004477' }"
                    icon="SaveIcon"
                    @click.stop="SaveDoctorRequest()"
                    class="cursor-pointer"
                    v-show="editDoctorRequest"
                  ></feather-icon>
                </b-col>
              </b-row>

              <b-card
                height="70%"
                class="mb-3"
                style="
                  border-radius: 16px;
                  opacity: 1;
                  background: #fefefe 0% 0% no-repeat padding-box;
                  width: 80%;
                "
              >
                <b-row>
                  <b-col cols="12" md="3">
                    <v-select
                      :disabled="!editDoctorRequest"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      class="w-full mb-4"
                      label="Name"
                      v-model="DoctorRequestModel.DocumentID"
                      :options="DocumentTypes"
                      :reduce="(ID) => ID.ID"
                      :placeholder="$t('DocumentType')"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                    v-if="DoctorRequestModel.DocumentID == 1"
                  >
                    <v-select
                      :disabled="!editDoctorRequest"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      class="w-full mb-10"
                      label="Name"
                      v-model="DoctorRequestModel.ScanTypeID"
                      :options="ScanTypes"
                      :reduce="(ID) => ID.ID"
                      :placeholder="$t('ScanType')"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                    v-if="DoctorRequestModel.DocumentID == 2"
                  >
                    <v-select
                      :disabled="!editDoctorRequest"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      class="w-full mb-10"
                      label="Name"
                      v-model="DoctorRequestModel.AnalysisTypeID"
                      :options="AnalysisTypes"
                      :reduce="(ID) => ID.ID"
                      :placeholder="$t('AnalysisType')"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                    v-if="DoctorRequestModel.DocumentID == 3"
                  >
                    <v-select
                      :disabled="!editDoctorRequest"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      class="w-full mb-10"
                      label="Name"
                      v-model="DoctorRequestModel.ReportTypeID"
                      :options="ReportTypes"
                      :reduce="(ID) => ID.ID"
                      :placeholder="$t('ReportType')"
                    />
                  </b-col>
                  <b-col cols="12" md="3">
                    <vs-input
                      :disabled="!editDoctorRequest"
                      class="w-full"
                      v-model="DoctorRequestModel.Description"
                      :placeholder="$t('Description')"
                    />
                  </b-col>
                  <b-col cols="12" md="2">
                    <feather-icon
                      :disabled="!editDoctorRequest"
                      style="margin-left: 10px; color: black"
                      icon="PlusIcon"
                      @click.stop="AddDoctorRequest()"
                      class="cursor-pointer"
                    ></feather-icon>
                  </b-col>
                </b-row>

                <div
                  v-if="
                    Model.PatientMedicalReportRequests != null &&
                    Model.PatientMedicalReportRequests != undefined
                  "
                >
                  <div>
                    <div id="data-list-list-view" class="data-list-container">
                      <vs-table
                        ref="table"
                        :data="Model.PatientMedicalReportRequests"
                      >
                        <div
                          slot="header"
                          class="
                            flex flex-wrap-reverse
                            items-center
                            flex-grow
                            justify-between
                          "
                        ></div>

                        <template slot-scope="{ data }">
                          <tbody
                            :data="item"
                            :key="indextr"
                            v-for="(item, indextr) in data"
                          >
                            <vs-tr class="cursor-pointer hover:text-primary">
                              <vs-td>
                                <p class="product-name font-medium truncate">
                                  {{ item.DoctorRequestType.Name }}
                                </p>
                              </vs-td>
                              <vs-td>
                                <p class="product-category">
                                  {{ item.Staus.Name }}
                                </p>
                              </vs-td>
                              <vs-td>
                                <feather-icon
                                  :disabled="!editDoctorRequest"
                                  style="margin-left: 10px; color: black"
                                  icon="XIcon"
                                  @click.stop="deleteDoctorRequest(index)"
                                  class="cursor-pointer"
                                ></feather-icon>
                              </vs-td>
                            </vs-tr>
                          </tbody>
                        </template>
                      </vs-table>
                    </div>
                  </div>
                </div>
              </b-card>
            </b-card-body>
            <hr class="invoice-spacing mt-0" />
            <b-card-body class="invoice-padding pt-0 pb-0">
              <b-row class="invoice-spacing">
                <b-col cols="12" md="10">
                  <p class="text-primary invoice-logo">
                    {{ $t("Prescription") }}
                  </p>
                </b-col>
                <b-col cols="12" md="2">
                  <feather-icon
                    :style="{
                      color: editPatientPrescription ? '#004477' : 'red',
                    }"
                    icon="EditIcon"
                    @click.stop="
                      editPatientPrescription = !editPatientPrescription
                    "
                    class="cursor-pointer"
                  ></feather-icon>
                  <feather-icon
                    style="color: primary; margin-left: 10px"
                    icon="SaveIcon"
                    :style="{
                      color: editPatientPrescription ? 'red' : '#004477',
                    }"
                    @click.stop="editPatientPrescription = false"
                    class="cursor-pointer"
                    v-show="editPatientPrescription"
                  >
                  </feather-icon>
                </b-col>
              </b-row>

              <b-card
                height="70%"
                class="mb-3"
                style="
                  border-radius: 16px;
                  opacity: 1;
                  background: #fefefe 0% 0% no-repeat padding-box;
                  width: 80%;
                "
              >
                <b-row>
                  <b-col cols="12" md="6">
                    <label for="">{{ $t("Medication") }}</label>
                    <v-select
                      :disabled="!editPatientPrescription"
                      label="name"
                      :filterable="false"
                      v-model="PrescriptionModel.Medicine"
                      :options="Medicins"
                      @search="onMedicinSearch"
                      :placeholder="$t('Medication')"
                    >
                      <template slot="no-options"> type to search </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          {{ option.TradeName }}
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          {{ option.TradeName }}
                        </div>
                      </template>
                    </v-select>
                  </b-col>

                  <b-col cols="12" md="6">
                    <vs-input
                      v-show="
                        PrescriptionModel.Medicine &&
                        !PrescriptionModel.Medicine.ID
                      "
                      :disabled="!editPatientPrescription"
                      :label="$t('MedicineName')"
                      v-model="PrescriptionModel.MedicineName"
                    />
                  </b-col>
                  <b-col cols="12" md="5">
                    <vs-input
                      :disabled="!editPatientPrescription"
                      :label="$t('Doosage')"
                      v-model="PrescriptionModel.Dose"
                    />
                  </b-col>
                  <b-col cols="12" md="5">
                    <vs-input
                      :disabled="!editPatientPrescription"
                      type="number"
                      :label="$t('DurationInDay')"
                      v-model="PrescriptionModel.DurationInDay"
                    />
                  </b-col>
                  <b-col cols="12" md="2">
                    <feather-icon
                      :disabled="!editPatientPrescription"
                      v-if="
                        PrescriptionModel.Medicine &&
                        ((PrescriptionModel.Medicine.ID == null &&
                          PrescriptionModel.MedicineName) ||
                          PrescriptionModel.Medicine.ID > 0) &&
                        PrescriptionModel.Dose &&
                        PrescriptionModel.DurationInDay
                      "
                      style="color: black"
                      icon="PlusIcon"
                      @click.stop="AddPatientPrescription()"
                    ></feather-icon>
                  </b-col>
                </b-row>

                <div
                  v-if="
                    Model.PatientReservationSessionPrescriptions != null &&
                    Model.PatientReservationSessionPrescriptions != undefined &&
                    Model.PatientReservationSessionPrescriptions.length > 0 &&
                    Model.PatientReservationSessionPrescriptions[0]
                      .PatientReservatientSessionMedicals
                  "
                >
                  <div>
                    <div id="data-list-list-view" class="data-list-container">
                      <vs-table
                        ref="table"
                        :data="
                          Model.PatientReservationSessionPrescriptions[0]
                            .PatientReservatientSessionMedicals
                        "
                      >
                        <template slot-scope="{ data }">
                          <tbody
                            :data="item"
                            :key="indextr"
                            v-for="(item, indextr) in data"
                          >
                            <vs-tr class="cursor-pointer hover:text-primary">
                              <vs-td>
                                <h2
                                  v-if="
                                    item.Medicine && item.Medicine.TradeName
                                  "
                                  style="color: #454a62; font-size: 19px"
                                >
                                  {{ item.Medicine.TradeName }}
                                </h2>
                                <h2
                                  v-else
                                  style="color: #454a62; font-size: 19px"
                                >
                                  {{ item.MedicineName }}
                                </h2>
                              </vs-td>
                              <vs-td>
                                <h2 style="color: #454a62; font-size: 19px">
                                  {{ item.Dose }}
                                </h2>
                              </vs-td>
                              <vs-td>
                                <h2 style="color: #454a62; font-size: 19px">
                                  {{ item.DurationInDay }}
                                </h2>
                              </vs-td>
                              <vs-td>
                                <feather-icon
                                  :disabled="!editPatientPrescription"
                                  style="margin-left: 10px; color: black"
                                  icon="XIcon"
                                  @click.stop="
                                    deletePatientPrescription(item, index)
                                  "
                                  class="cursor-pointer"
                                ></feather-icon>
                              </vs-td>
                            </vs-tr>
                          </tbody>
                        </template>
                      </vs-table>
                    </div>
                  </div>
                </div>
              </b-card>
            </b-card-body>
          </b-card>
        </b-col>

        <!-- Right Col: Card -->
        <b-col cols="12" md="4" xl="3" class="invoice-actions">
          <b-card>
            <b-col>
              <b-button
                variant="white"
                v-if="Model.DoctorSession"
                class="text-2xl w-full"
                >{{ $t("Time") }}:
                <span class="text-primary">{{
                  Model.DoctorSession.DateFrom.replace("T", " ")
                }}</span>
              </b-button>
              <b-button
                variant="primary"
                @click="JoinMeeting()"
                v-if="!Model.DoctorJoined"
                class="mt-1"
              >
                {{ $t("Join") }}
              </b-button>

              <b-button
                variant="primary"
                @click="ScheduleAnotherAppintment()"
                class="mt-1"
              >
                {{ $t("ScheduleAnotherAppintment") }}
              </b-button>
            </b-col>
          </b-card>
        </b-col>
      </b-row>
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <b-button type="border" color="danger" @click="back()">{{
              $t("Back")
            }}</b-button>
          </div>
        </div>
      </div>
    </section>
    <vs-popup title="" :active.sync="showDownloadReportsfield">
      <showDownloadReportsCom
        :patientDoc="patientDoc"
        v-if="showDownloadReportsfield"
        @closePop="showDownloadReportsfield = false"
      ></showDownloadReportsCom>
    </vs-popup>
    <vs-popup title="" :active.sync="showPatientSymptoms">
      <PatientsSymptomsDescription
        :PatientReservationID="PatientReservationID"
        v-if="showPatientSymptoms"
        @closePop="showPatientSymptoms = false"
      ></PatientsSymptomsDescription>
    </vs-popup>

    <vs-popup title="" :active.sync="ShowJoinPopup">
      <JoinSessionAlertPopup
        :sessionModel="sessionModel"
        v-if="ShowJoinPopup"
        @closePop="ShowJoinPopup = false"
        @joinSession="ChangeReservationJoin"
      >
      </JoinSessionAlertPopup>
    </vs-popup>
  </div>
</template>
<script>
import imageLazy from "@/components/image_lazy.vue";
import { domain, mainSetting } from "@/gloabelConstant.js";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import modulePatient from "@/store/Patient/modulePatient.js";
import moduleAnalysisType from "@/store/settings/analysisType/moduleAnalysisType.js";
import moduleDiagnosis from "@/store/settings/Diagnosis/moduleDiagnosis.js";
import moduleDoctorRequestType from "@/store/settings/doctorRequestType/moduleDoctorRequestType.js";
import moduleDocumentType from "@/store/settings/documentType/moduleDocumentType.js";
import moduleMedicine from "@/store/settings/Medicine/moduleMedicine.js";
import moduleReportType from "@/store/settings/reportType/moduleReportType.js";
import moduleScanType from "@/store/settings/scanType/moduleScanType.js";
import PatientsSymptomsDescription from "@/views/doctor/Patients/PatientsSymptomsDescription.vue";
import showDownloadReportsCom from "@/views/doctorProfile/pendingMedicalReportsDownload.vue";
import JoinSessionAlertPopup from "@/views/Patient/Reservation/JoinSessionAlertPopup.vue";
import {
BAlert,
BButton,
BCard,
BCardBody,
BCardText,
BCol,
BFormTextarea,
BLink,
BRow,
BTableLite
} from "bootstrap-vue";

export default {
  data() {
    return {
      //DateRequest:{},
      PrescriptionModel: {},
      baseURL: domain,
      uploadUrl: domain + "api/Common/UploadFile",
      showDownloadReportsfield: false,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      Model: {},
      patientDoc: {},
      DiagnosModel: {},
      PatientMedicalReportRequests: [],
      DoctorRequestModel: {},
      editDoctorNote: false,
      editPatientDiagnosis: false,
      editPatientPrescription: false,
      editDoctorRequest: false,
      editPatientDiagnosisNote: false,
      showPatientSymptoms: false,
      PatientReservationID: 0,
      ShowJoinPopup: false,
      sessionModel: {},
    };
  },

  components: {
    showDownloadReportsCom,
    PatientsSymptomsDescription,
    JoinSessionAlertPopup,
    imageLazy,
    BAlert,
    BButton,
    BCard,
    BCardBody,
    BCardText,
    BCol,
    BLink,
    BRow,
    BTableLite,
    BFormTextarea,
  },
  computed: {
    Diagnosis() {
      return this.$store.state.DiagnosisList.Diagnosis;
    },
    Medicins() {
      var Medicines = this.$store.state.medicineList.Medicines;
      Medicines.push({ ID: null, TradeName: "Other" });
      return Medicines;
    },
    DoctorRequestTypes() {
      return this.$store.state.DoctorRequestTypeList.DoctorRequestTypes;
    },
    ScanTypes() {
      return this.$store.state.ScanTypeList.ScanTypes;
    },

    DocumentTypes() {
      return this.$store.state.DocumentTypeList.DocumentTypes;
    },

    ReportTypes() {
      return this.$store.state.ReportTypeList.ReportTypes;
    },
    AnalysisTypes() {
      return this.$store.state.AnalysisTypeList.AnalysisTypes;
    },
  },
  methods: {
    onMedicinSearch(search, loading) {
      debugger;
      this.$store.dispatch("medicineList/SearchMedicines", search);
    },
    ChangeReservationJoin(reservationId) {
      this.$store
        .dispatch("patientList/ChangeReservationJoin", reservationId)
        .then((res) => {
          this.getSession();
        });
    },

    AddPatientPrescription() {
      debugger;
      this.$vs.loading();
      if (
        !this.Model.PatientReservationSessionPrescriptions ||
        this.Model.PatientReservationSessionPrescriptions.length == 0
      ) {
        this.Model.PatientReservationSessionPrescriptions = [];
        this.Model.PatientReservationSessionPrescriptions.push({
          PatientReservatientSessionMedicals: [],
        });
      }
      if (
        !this.Model.PatientReservationSessionPrescriptions[0]
          .PatientReservatientSessionMedicals
      )
        this.Model.PatientReservationSessionPrescriptions[0].PatientReservatientSessionMedicals =
          [];

      this.PrescriptionModel.PatientReservedSessionID = this.Model.ID;

      if (this.PrescriptionModel.Medicine.ID == null)
        this.PrescriptionModel.Medicine = {};
      this.$store
        .dispatch("DoctorList/AddMedicalPrescription", this.PrescriptionModel)
        .then((res) => {
          this.$vs.loading.close();
          window.showSuccess();
          this.PrescriptionModel.ID = res.data.Data.ID;
          this.Model.PatientReservationSessionPrescriptions[0].PatientReservatientSessionMedicals.push(
            this.PrescriptionModel
          );
          this.PrescriptionModel = {};
        });
    },

    SavePrescription() {
      this.$vs.loading();
      this.$store
        .dispatch(
          "DoctorList/CompletePatientReservation",
          this.$route.params.PatientReservationID
        )
        .then((res) => {
          this.$vs.notify({
            title: this.$t("success"),
            text: res.data.Message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
          });
          this.$vs.loading.close();
          this.getAppointmentSessions(false);
        })
        .catch((err) => {
          this.$vs.loading.close();
          debugger;
          this.$vs.notify({
            title: this.$t("Error"),
            text: err.data.Message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
    deletePatientPrescription(medicine, index) {
      debugger;
      this.$store
        .dispatch("DoctorList/DeleteMedicalPrescription", medicine)
        .then((res) => {
          this.Model.PatientReservationSessionPrescriptions[0].PatientReservatientSessionMedicals.splice(
            index,
            1
          );
        });
    },

    createSeeionMeeting(sesstionId) {
      this.$store
        .dispatch("auth/CreateMeetingSession", sesstionId)
        .then((res) => {
          if (res.status == 200) {
            this.sessionModel.JoinUrl = res.data.Data.ModeratorUrl;
            this.ShowJoinPopup = true;
          }
        });
    },
    JoinMeeting() {
      debugger;
      this.sessionModel.ID = this.Model.ID;
      //this.sessionModel.JoinUrl = this.Model.ModeratorUrl;
      // "2021-12-31T17:50:00"
      var DateNow = new Date();
      var DateStart = new Date(this.Model.DoctorSession.DateFrom);
      const milliseconds = DateStart - DateNow;

      var Minutes = Math.floor(milliseconds / 60000);
      // / 36e5
      debugger;

      if (Minutes <= 5) {
        this.createSeeionMeeting(this.Model.ID);
      } else {
        this.$vs.notify({
          color: "danger",
          position: "right-bottom",
          text: "this meeting can't start now",
        });
      }
    },
    back() {
      this.$router.go(-1);
    },
    showDownloadReports(PatientID, DoctorID) {
      debugger;
      this.patientDoc.PatientID = PatientID;
      this.patientDoc.DoctorID = DoctorID;
      this.showDownloadReportsfield = true;
    },
    showPatientHistory(PatientID) {
      debugger;
      this.$router.push({
        name: "PatientMedicalHistorySession",
        params: { ID: PatientID },
      });
    },
    AddPatientDiagnosis() {
      debugger;
      var model = {};
      model.DiagnosisID = this.DiagnosModel.Diagnos.ID;
      model.Diagnosis = this.DiagnosModel.Diagnos;
      model.PatientReservationSessionID = this.Model.ID;
      this.Model.PatientReservationSessionDiagnosis.push(model);
      this.DiagnosModel = {};
    },
    AddDoctorRequest() {
      debugger;
      var Staus = {};
      Staus.Name = "Pending";
      var model = {};
      var vm = this;
      model.DoctorRequestTypeID = this.DoctorRequestModel.DocumentID;
      model.ScanTypeID = this.DoctorRequestModel.ScanTypeID;
      model.ReportTypeID = this.DoctorRequestModel.ReportTypeID;
      model.AnalysisTypeID = this.DoctorRequestModel.AnalysisTypeID;
      model.Description = this.DoctorRequestModel.Description;
      model.DoctorID = this.Model.DoctorID;
      model.StausID = 1; //pending
      model.DoctorRequestType = this.DocumentTypes.find(
        (obj) => obj.ID == vm.DoctorRequestModel.DocumentID
      );
      model.Staus = Staus;
      model.Date = new Date();

      model.PatientReservationSessionID = this.Model.ID;
      this.Model.PatientMedicalReportRequests.push(model);
      this.DoctorRequestModel = {};
    },
    deleteDoctorRequest(index) {
      // const ItemIndex = this.Model.PatientMedicalReportRequests.findIndex(
      //   (p) => p.DoctorRequestTypeID == data.DoctorRequestTypeID
      // );
      this.Model.PatientMedicalReportRequests.splice(index, 1);
    },
    deletePatientDiagnosis(index) {
      // debugger;
      // const ItemIndex = this.Model.PatientReservationSessionDiagnosis.findIndex(
      //   (p) => p.DiagnosisID == data.DiagnosisID
      // );

      this.Model.PatientReservationSessionDiagnosis.splice(index, 1);
    },

    openPatientsSymptoms(ID) {
      this.showPatientSymptoms = true;
      this.PatientReservationID = ID;
    },
    SaveDoctorNotePatientDiagnosisNote() {
      debugger;
      this.$vs.loading();
      var model = {};
      model.PatientID = this.Model.PatientID;
      model.DoctorSessionID = this.Model.DoctorSessionID;
      model.DoctorID = this.Model.DoctorID;
      model.PaymentStatusID = this.Model.PaymentStatusID;
      model.PrescriptionNote = this.Model.PrescriptionNote;
      model.DoctorComment = this.Model.DoctorComment;
      model.SymptomNote = this.Model.SymptomNote;
      model.RequestedReportNote = this.Model.RequestedReportNote;
      model.DiagnosisNote = this.Model.DiagnosisNote;
      model.StatusID = this.Model.StatusID;
      model.ID = this.Model.ID;

      this.$store
        .dispatch("patientList/UpdatePatientReservationSession", model)
        .then(() => {
          this.$vs.loading.close();
          window.showSuccess();
          this.editDoctorNote = false;
          this.editPatientDiagnosisNote = false;
        })
        .catch(() => {
          this.$vs.loading.close();
        });
      this.editDoctorNote = false;
      this.editPatientDiagnosisNote = false;
    },
    SavePatientDiagnosis() {
      this.$vs.loading();
      this.$store
        .dispatch(
          "patientList/UpdateListPatientReservationSessionDiagnosis",
          this.Model.PatientReservationSessionDiagnosis
        )
        .then(() => {
          this.$vs.loading.close();
          window.showSuccess();
          this.editPatientDiagnosis = false;
        })
        .catch(() => {
          this.$vs.loading.close();
        });
      this.editPatientDiagnosis = false;
    },
    SaveDoctorRequest() {
      debugger;
      this.$vs.loading();
      var model = {};
      model.PatientReservationID = parseInt(
        this.$route.params.PatientReservationID
      );
      model.PatientMedicalReportRequestDTOs =
        this.Model.PatientMedicalReportRequests;
      this.$store
        .dispatch("patientList/UpdateListPatientMedicalReportRequest", model)
        .then(() => {
          this.$vs.loading.close();
          window.showSuccess();
          this.editDoctorRequest = false;
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },

    getSession() {
      debugger;
      this.$vs.loading();
      debugger;
      var DateRequest = {};
      DateRequest.sessionID = this.$route.params.PatientReservationID;
      if (
        this.$route.params.IsDoctorRequest != null &&
        this.$route.params.IsDoctorRequest != undefined
      )
        DateRequest.IsDoctorRequest = this.$route.params.IsDoctorRequest;
      else {
        DateRequest.IsDoctorRequest = true;
      }
      this.$store
        .dispatch(
          "DoctorList/GetPatientReservationSessionViaDoctor",
          DateRequest
        )
        .then((res) => {
          debugger;
          this.Model = res.data.Data;
          debugger;
          if (this.Model.PatientMedicalReportRequests != null) {
            this.PatientMedicalReportRequests =
              this.Model.PatientMedicalReportRequests;
          }
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    ScheduleAnotherAppintment() {
      const sessionID = this.$route.params.PatientReservationID;
      this.$store
        .dispatch("patientList/ScheduleAnotherAppintment", sessionID)
        .then((res) => {
          this.$vs.notify({
            title: "successfully",
            text: res.data.Message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
          });
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
  },
  created() {
    if (!moduleScanType.isRegistered) {
      this.$store.registerModule("ScanTypeList", moduleScanType);
      moduleScanType.isRegistered = true;
    }

    this.$store.dispatch("ScanTypeList/GetAllScanTypes");

    if (!moduleReportType.isRegistered) {
      this.$store.registerModule("ReportTypeList", moduleReportType);
      moduleReportType.isRegistered = true;
    }

    this.$store.dispatch("ReportTypeList/GetAllReportTypes");
    if (!moduleAnalysisType.isRegistered) {
      this.$store.registerModule("AnalysisTypeList", moduleAnalysisType);
      moduleAnalysisType.isRegistered = true;
    }

    this.$store.dispatch("AnalysisTypeList/GetAllAnalysisTypes");
    if (!moduleDocumentType.isRegistered) {
      this.$store.registerModule("DocumentTypeList", moduleDocumentType);
      moduleDocumentType.isRegistered = true;
    }

    this.$store.dispatch("DocumentTypeList/GetAllDocumentTypes");

    if (!moduleMedicine.isRegistered) {
      this.$store.registerModule("medicineList", moduleMedicine);
      moduleMedicine.isRegistered = true;
    }
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }

    if (!moduleDoctorRequestType.isRegistered) {
      this.$store.registerModule(
        "DoctorRequestTypeList",
        moduleDoctorRequestType
      );
      moduleDoctorRequestType.isRegistered = true;
    }
    debugger;
    this.$store.dispatch("DoctorRequestTypeList/GetAllDoctorRequestTypes");
    if (!moduleDiagnosis.isRegistered) {
      this.$store.registerModule("DiagnosisList", moduleDiagnosis);
      moduleDiagnosis.isRegistered = true;
    }

    //this.$store.dispatch("medicineList/GetAllMedicines");
    this.$store.dispatch("medicineList/SearchMedicines", "");
    this.$store.dispatch("DiagnosisList/GetAllDiagnosis");
    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    this.getSession();
  },
};
</script>
<style>
#sessionDetail .vs-button-text {
  margin: auto;
  width: 100%;
}

#sessionDetail table {
  border-collapse: collapse;
  border-radius: 2em;
  overflow: hidden;
}

#sessionDetail th,
td {
  border: 1px solid rgb(255, 255, 255);
  border-collapse: collapse;
}

#sessionDetail .mycard .vx-card {
  /* padding: 0px !important; */
  background: unset;
}

#sessionDetail .login-tabs-container {
  min-height: 505px;
}

#sessionDetail .con-tab {
  padding-bottom: 14px;
}

#sessionDetail .con-slot-tabs {
  margin-top: 1rem;
}

.background {
  color: rgb(255, 9, 9);
}

#sessionDetail .stack-top {
  z-index: 9;
  border-radius: 5px;
  background: #202020;
}

#sessionDetail .head {
  color: white;
  text-align: center;
  margin-top: 20px;
}
</style>


<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 10px;
          width: fit-content;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>