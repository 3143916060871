/*=========================================================================================
  File Name: moduleMedicineActions.js
  Description: Medicine Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";

export default {
  AddMedicine({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Medicine/AddMedicine", item)
        .then(response => {
          commit(
            "ADD_ITEM",
            Object.assign(item, { ID: response.data.Data.ID })
          );
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  GetAllMedicines({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Medicine/GetAllMedicines")
        .then(response => {
          commit("SET_Medicine", response.data.Data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  SearchMedicines({ commit },query) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Medicine/SearchMedicines?query="+query)
        .then(response => {
          commit("SET_Medicine", response.data.Data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  UpdateMedicine({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Medicine/UpdateMedicine", item)
        .then(response => {
          commit("UPDATE_Medicine", item);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  GetMedicine({ commit }, itemid) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Medicine/GetMedicine", itemid)
        .then(response => {
          commit("UPDATE_Medicine", response.data.Data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  DeleteMedicine({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .delete("api/Medicine/DeleteMedicine?ID=" + item.ID)
        .then(response => {
          commit("REMOVE_ITEM", item.ID);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
